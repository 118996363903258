//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

.loading-over {
  filter: blur(3px);
}
.loading-text {
  display: block;
  top: 50%;
  position: fixed;
  left: 50%;
  z-index: 99999;
}
.location-label {
  padding: 0.825rem 1.5rem;
  background: #f5f8fa;
  border-radius: 4px;
  font-size: 1.15rem;
  color: #5e6278;
}


input.input-number-appearance::-webkit-outer-spin-button,
input.input-number-appearance::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] .input-number-appearance {
  -moz-appearance: textfield;
}


.spreadsheet-area tbody tr:nth-child(even) {
  background-color: #eee;
}

.max-h-750{
  max-height: 650px;
}

.overflow-vertical{ 
  overflow-y: scroll;
}

.display-contents {
  display: contents;
}